<template>
  <div>
    <div v-if="isDetailItemLoaded">
    <div v-if="canCreateChildMeasure && isMeasureDetailPage">
      <v-select
        :options="formattedChildMeasureIds"
        label="Navigate to a Child Measure"
        @input="handleSelection"
      />
    </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import VSelect from '@/components/v-select'
import ApiClient from '@/assets/js/ApiClient.js'

export default {
  components: {
    'v-select': VSelect
  },
  data () {
    return {
      childLabels: {}
    }
  },
  computed: {
    ...mapGetters('results/measures', ['childrenSelectedAttributes', 'childMeasureIds', 'canCreateChildMeasure']),
    isDetailItemLoaded () {
      return !!this.$store.state.results.measures.detailItem
    },
    formattedChildMeasureIds () {
      return this.childMeasureIds.map((id, index) => {
        const childInfo = this.childLabels[id] || { label: 'no label', inheritDataText: 'Does not inherit data' }
        return {
          label: `${childInfo.label}`,
          description: `[${childInfo.inheritDataText}]`,
          href: `/results/measures/${id}`
        }
      })
    },
    isMeasureDetailPage () {
      return /^\/results\/measures\/\d+$/.test(this.$route.path)
    }
  },
  methods: {
    async fetchChildLabels () {
      for (const id of this.childMeasureIds) {
        if (!this.childLabels[id]) {
          const endpoint = `results/measures/${id}/get_child_label_and_inheritance/`
          ApiClient.get(endpoint).then(response => {
            if (response.data) {
              const label = response.data.label || 'no label'
              const inheritDataText = response.data.inherit_data ? 'Inherits data' : 'Does not inherit data'
              this.$set(this.childLabels, id, { label, inheritDataText })
            } else {
              this.$set(this.childLabels, id, { label: 'no label', inheritDataText: 'Does not inherit data' })
            }
          }).catch(err => {
            console.error(`Error fetching label and inheritance for measure ${id}:`, err.response.data.detail)
            this.$set(this.childLabels, id, { label: 'no label', inheritDataText: 'Does not inherit data' })
          })
        }
      }
    },
    handleSelection (selectedOption) {
      if (selectedOption && typeof selectedOption === 'object') {
        const id = this.extractIdFromHref(selectedOption.href)
        this.redirectToMeasure(id)
      }
    },
    extractIdFromHref (href) {
      // Assuming href is in the format `/results/measures/{id}`
      const idMatch = href.match(/\/results\/measures\/(\d+)$/)
      return idMatch ? idMatch[1] : null
    },
    redirectToMeasure (id) {
      if (id) {
        this.$router.push({ path: `/results/measures/${id}` })
      }
    }
  },
  watch: {
    childMeasureIds (newIds) {
      if (newIds.length) {
        this.fetchChildLabels() // Fetch labels when child measure IDs change
      }
    }
  },
  mounted () {
    if (this.childMeasureIds.length) {
      this.fetchChildLabels()
    }
  }
}
</script>

<style scoped>
.dropdown {
  max-width: 300px;
}
</style>
