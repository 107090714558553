<template>
  <div class="w-full">
    <div v-if="user.is_staff && filters" class="flex space-x-3 py-2 px-3 items-center bg-neutral-100 dark:bg-neutral-900 mb-2">
      <span class="uppercase text-xs text-neutral-600 tracking-wider">Filtern nach:</span>
      <div v-for="(filter, index) in filters" :key="index">
        <v-select
          v-if="activeFilters[index] || filter.singleSelect"
          v-model="activeFilters[index]"
          :multiselect="filter.singleSelect === undefined"
          :label="'Alle ' + filter.name"
          :options="filter.options"
          :small="true"
          @changed="updateFilter"
        ></v-select>
      </div>
    </div>
    <!-- This chart menu is used only when v-table is displayed as a graphic and chart-menu - it's burger menu where you can change values -->
    <chart-menu v-if="!!chart" :chart="chart"></chart-menu>

    <div class="w-full overflow-auto">
      <!-- This table is displayed evey time where there is data -->
      <table v-if="filteredItems && filteredItems.length" class="table-fixed" >
        <thead class="bg-neutral-100 dark:bg-neutral-900 min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
          <tr>
            <th v-if="showSelect" class="w-12"></th>
            <th v-for="(header, index) in filteredHeaders"
              :key="(header.valueCalc || header.value)" :style="getStyleForHeader(header, index)"
              scope="col"
              class="px-3 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider"
              :class="[path && path !== 'filemanager/files' ? 'cursor-pointer' : '' && [!!chart ? 'break-words' : 'whitespace-nowrap']]"
              @click="[(path && path !== 'filemanager/files' || !!chart)  ? sortBy(header) : null]"
            >
              <div class="flex items-center">
              <span v-tooltip="header.text" :class="[!(!!chart) && 'truncate']">{{header.textHeader || header.text}}</span>
              <svg transform="scale(1,-1)" v-if="sortKey === (header.valueCalc || header.order || header.value)"
                class="shrink-0 ml-1 w-5 h-5 text-neutral-400 fill-current"
                :class="{'transform' : sortOrder === -1 }"
                xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"
                >
                <path d="M0 0h24v24H0z" fill="none"/><path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z"/>
              </svg>
              </div>
            </th>
            <th v-if="canDelete" class="w-12"></th>
            <th class="w-12"></th>
          </tr>
        </thead>
        <tbody class="bg-white dark:bg-neutral-800 dark:text-neutral-100 divide-y divide-neutral-200 dark:divide-neutral-800">
          <tr
            v-for="(item, index) in filteredItems"
            :key="`${index}_${item[itemKey]}`"
            :class="[selectable || showSelect ? 'cursor-pointer' : '', index % 2 === 0 ? '' : 'bg-neutral-50 dark:bg-neutral-900']"
            @click="selectable ? onClickRow(item) : showSelect"
          >
          <!-- checkbox in pop-up window -->
            <td v-if="showSelect" class="w-12">
              <v-checkbox :value="isSelected(item)" @change="toggleSelect(item)"></v-checkbox>
            </td>
            <!-- list with emails vorname and nachname -->
            <td
              v-for="header in filteredHeaders"
              :key="(header.valueCalc || header.value)"
              :class="[!!chart ? 'break-words' : 'whitespace-nowrap']"
            >
              <slot :name="`item.${header.value}`" :item="item">
              <v-checkbox v-if="header.format === 'Boolean'" v-model="item[header.value]" @change="itemChanged(item)"></v-checkbox>
              <div v-else-if="header.format === 'BooleanView'" class="flex ml-2 w-full">
                <div class="h-3 w-3 rounded-full" :class="[item[header.value] ? 'bg-emerald-600' : 'bg-neutral-400']"></div>
              </div>
              <!-- case in evaluation in tab "einstellungen" that renders list of users in Administrationsrechte and after clicking btn administrationsrechte erteilen-->
              <div v-else v-html="renderItem(header, item)" :class="[!(!!chart) && 'truncate']" v-tooltip="getTooltipAttribute(header, item)">
              </div>
              </slot>
            </td>
            <td v-if="canDelete" class="w-12">
              <div @click="itemDelete(item)" class="cursor-pointer flex items-center px-2"><i class="material-icons">delete</i></div>
            </td>
            <td v-if='!isIrpDashboard' class="w-12">
              <MoreMenu
                :index="filteredItems.length-index-1"
                :length="filteredItems.length"
                :item="item"
                :path="path"
                :actionPathName="'detailActions'"
                class="ml-2"
              >
              </MoreMenu>
            </td>
            <td v-if="isIrpDashboard" class="w-12">
              <button>Herunterladen</button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- This table is working when there is no data -->
      <table v-else class="table-fixed" >
        <thead class="bg-neutral-100 dark:bg-neutral-900 min-w-full divide-y divide-neutral-200 dark:divide-neutral-800">
          <tr>
            <th v-if="showSelect" class="w-12"></th>
            <th v-for="(header, index) in filteredHeaders"
              :key="(header.valueCalc || header.value)" :style="getStyleForHeader(header, index)"
              scope="col"
              class="px-3 py-3 text-left text-xs font-medium text-neutral-500 uppercase tracking-wider cursor-pointer whitespace-nowrap"
            >
              <div class="flex items-center">
              {{header.text}}
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="bg-white dark:bg-neutral-900 dark:text-neutral-100 divide-y divide-neutral-200 dark:divide-neutral-800">
          <tr>
            <td :colspan="filteredHeaders.length" class="w-full text-center font-bold">
              {{pickTranslationFromJson ? pickTranslationFromJson(language, 'no-data') : $t('no-data') }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import MoreMenu from '@/components/MoreMenu.vue'
import VCheckbox from '@/components/v-checkbox'
import dateMixin from '@/mixins/date'
import { get } from 'lodash'
import { mapGetters } from 'vuex'
import VSelect from '@/components/v-select.vue'
import ChartMenu from '@/components/charts/ChartMenu.vue'

function isoTimeStringFormatter (isoTimeString) {
// ISO time-format string
  const date = new Date(isoTimeString)

  // Get the day, month, and year components from the Date object
  const day = date.getDate()
  const month = date.getMonth() + 1 // January is 0, so we add 1 to get the correct month
  const year = date.getFullYear() % 100 // Get the last two digits of the year

  // Format the date components as strings with leading zeros if necessary
  const formattedDay = String(day).padStart(2, '0')
  const formattedMonth = String(month).padStart(2, '0')
  const formattedYear = String(year).padStart(2, '0')

  // Combine the formatted date components into the desired format
  return `${formattedDay}.${formattedMonth}.20${formattedYear}`
}

export default {
  mixins: [dateMixin],
  components: {
    'v-checkbox': VCheckbox,
    MoreMenu,
    VSelect,
    ChartMenu
  },
  model: {
    prop: 'selection',
    event: 'select'
  },
  data () {
    return {
      activeFilters: [],
      dataSelection: []
    }
  },
  props: {
    path: {
      type: String,
      default: null
    },
    headers: {
      type: Array,
      required: true
    },
    isIrpDashboard: {
      type: Boolean,
      default: false
    },
    filters: {
      type: Array,
      default: null
    },
    items: {
      type: Array,
      required: true
    },
    chart: {
      type: Object,
      default: null
    },
    itemKey: {
      type: String,
      default: 'id'
    },
    showSelect: {
      type: Boolean,
      default: false
    },
    canDelete: {
      type: Boolean,
      default: false
    },
    selectable: {
      type: Boolean,
      default: false
    },
    ignoreLink: {
      type: Boolean,
      default: false
    },
    ignoreHideList: {
      type: Boolean,
      default: false
    },
    selection: {
      type: Array,
      default: () => []
    },
    searchText: {
      type: String,
      default: ''
    },
    language: {
      type: String,
      default: 'de'
    },
    pickTranslationFromJson: {
      type: Function,
      default: () => ''
    }
  },
  computed: {
    ...mapGetters(['sortOrder', 'sortKey', 'user', 'query']),
    isMailTemplatesOnly () {
      return this.$route.path.startsWith('/mailer/mailtemplates')
    },
    filteredHeaders () {
      if (this.ignoreHideList === true) {
        return this.headers
      }
      return this.headers.filter(h => h.hideList === undefined || h.hideList === false || (typeof h.hideList === 'function' && h.hideList() === false))
    },
    filteredItems () {
      var datas = this.items
      if (this.searchText !== '') {
        datas = datas.filter(d => {
          return Object.keys(d).filter(key => {
            const header = this.filteredHeaders.find(h => h.value === key)
            if (header && (header.type === undefined || header.type === 'number') && !Array.isArray(d[header.value])) {
              return String(this.renderItem(header, d)).toLowerCase().includes(this.searchText.toLowerCase())
            }
          }).length > 0
        })
      }
      if (this.isMailTemplatesOnly) {
        if (datas.some(item => item.modified)) {
          for (const i of datas) {
            i.modified = isoTimeStringFormatter(i.modified)
          }
        }
      }
      return datas
    }
  },
  methods: {
    truncateText (text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...'
      }
      return text
    },
    getTooltipAttribute (header, item) {
      if (header.format !== 'link') {
        if (header.width === -1) {
          const text = this.renderItem(header, item) || ''
          const modified = this.truncateText(text, 60)
          return modified
        }
      }
    },
    itemDelete (item) {
      this.$emit('item-delete', item)
    },
    sortBy (header) {
      const tmp = header.order || header.value
      if (this.sortKey !== tmp) {
        this.$store.commit('setSortOrder', 1)
      } else {
        this.$store.commit('setSortOrder', this.sortOrder * -1)
      }
      if (header.order) {
        this.$store.commit('setSortKey', header.order)
      } else {
        this.$store.commit('setSortKey', header.valueCalc || header.value)
      }
      const query = Object.assign({}, this.$route.query)
      query.ordering = this.sortOrder > 0 ? this.sortKey : `-${this.sortKey}`
      this.updateOrdering(query)
    },
    async updateOrdering (query) {
      if (this.path === null && !(this.chart)) {
        return
      }
      query = this.getFilterQuery(query)
      await this.$store.dispatch(`${this.path}/load`, { query: query })
    },
    getFilterQuery (query) {
      if (this.filters) {
        this.filters.forEach((filter, index) => {
          const activeFilter = this.activeFilters[index]
          if (activeFilter && activeFilter.length) {
            // Take infos from option
            if (activeFilter[0].field !== undefined) {
              activeFilter.forEach(f => {
                if (typeof f.query_value === 'object') {
                  query[f.field] = f.query_value.selected
                } else {
                  query[f.field] = f.query_value
                }
              })
            } else {
              if (typeof activeFilter[0] === 'object') {
                query[filter.field] = activeFilter.map(f => f.selected).join()
              } else {
                query[filter.field] = activeFilter.join()
              }
            }
          } else {
            if (activeFilter > 0) {
              query[filter.field] = activeFilter
            } else {
              if (query[filter.field] !== undefined) {
                delete query[filter.field]
              }
            }
          }
        })
      }
      return query
    },
    async updateFilter () {
      if (this.path === null) {
        return
      }
      var query = Object.assign({}, this.$route.query)
      query = { ...query, ...this.query }
      query = this.getFilterQuery(query)
      await this.$store.dispatch(`${this.path}/load`, { query: query })
      this.$store.commit('setQuery', query)
    },
    getStyleForHeader (header, index) {
      if (header.width !== null) {
        let width = ''
        if (header.width === -1) {
          width = '100%'
        } else if (header.width > 0) {
          width = header.width + 12 + 'px'
        } else {
          width = 'auto'
        }
        return { width }
      }
      return null
    },
    toggleSelect (item) {
      var index = this.itemKey === null ? this.dataSelection.findIndex(c => c.id === item.id) : this.dataSelection.indexOf(item[this.itemKey])
      if (index > -1) {
        this.dataSelection.splice(index, 1)
      } else {
        this.dataSelection.push(this.itemKey === null ? item : item[this.itemKey])
      }
      this.$emit('select', this.dataSelection, this.itemKey === null ? item : item[this.itemKey], (index === -1))
    },
    isSelected (item) {
      return this.itemKey === null ? this.dataSelection.filter(s => s.id === item.id).length > 0 : this.dataSelection.indexOf(item.id) > -1
    },
    renderItem (header, item) {
      if (header.format === 'DateTime') {
        return this.formatDateTime(get(item, header.value))
      }
      if (header.format === 'Date') {
        return this.formatDate(get(item, header.value))
      }
      if (header.renderItem) {
        return get(item, header.value) !== undefined ? header.renderItem(get(item, header.value), item) : '-'
      }
      if (Array.isArray(item[header.value])) {
        return `${item[header.value].length} ${header.text}`
      }
      if (header.format === 'Link' && !this.ignoreLink) {
        const link = header.getLink(item)
        const description = header.getDescription(item)
        return `<a class="text-secondary underline" href="${link}">${description}</a>`
      }
      if (header.type === 'select') {
        const selected = header.options.find(o => o.id === item[header.value])
        return selected ? selected.value : ''
      }
      return get(item, header.value)
    },
    onClickRow (item) {
      const isTextHighlighting = window.getSelection().toString().trim() !== ''
      if (!isTextHighlighting) {
        this.$emit('click', item)
      }
    },
    itemChanged (item) {
      this.$emit('item-changed', item)
    }
  },
  mounted () {
    if (this.filters) {
      this.filters.forEach(f => {
        this.activeFilters.push([])
      })
    }
    this.dataSelection = this.selection
  },
  watch: {
    filteredItems (newVal) {
      this.$emit('update-entries', newVal) // Emit event when computedEntries changes
    }
  }
}
</script>
