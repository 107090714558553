
export default {
  methods: {
    setBenchmarksCategory (divArray, element) {
      const offset = divArray.length * 30
      const stackedBar = element.querySelector('.apexcharts-grid-borders')
      const existingElement = element.querySelector('.apexcharts-inner.apexcharts-graphical')
      const foreignObject = document.createElementNS('http://www.w3.org/2000/svg', 'foreignObject')
      const getElement = stackedBar.children[0]
      const newElement = document.createElement('div')
      const getWidthEnd = getElement.getAttribute('x2')
      const getWidthStart = getElement.getAttribute('x1')
      const bbox = existingElement.getBBox()
      const bottomPosition = bbox.y + bbox.height

      newElement.setAttribute('xmlns', 'http://www.w3.org/1999/xhtml')
      foreignObject.setAttribute('width', 'calc(100% - 48px)')
      foreignObject.setAttribute('width', `${getWidthEnd - getWidthStart}px`)
      foreignObject.setAttribute('height', `${offset}px`)
      foreignObject.setAttribute('x', `${getWidthStart}px`)
      foreignObject.setAttribute('y', bottomPosition + 10)

      for (const i of divArray) {
        const element = document.createElement('div')
        element.style.borderTop = '2px solid #e0e0e0'
        element.style.width = '100%'
        element.style.minHeight = '30px'
        element.style.position = 'relative'
        element.style.display = 'flex'
        for (const i0 in i) {
          const index = Number(i0)
          const elements = document.createElement('span')
          elements.innerHTML = `<span style="position:relative;top:6px;">${i[i0]}</span>`
          if (index === i.length - 1) { elements.style.borderRight = '1px solid #e0e0e0' }
          elements.style.fontFamily = 'Nunito, sans-serif'
          elements.style.borderLeft = '1px solid #e0e0e0'
          elements.style.fontSize = '12px'
          elements.style.textAlign = 'center'
          elements.style.width = `${100 / i.length}%`
          elements.style.height = '10px'
          elements.style.display = 'block'
          element.appendChild(elements)
        }
        newElement.appendChild(element)
      }
      foreignObject.innerHTML = ''
      foreignObject.appendChild(newElement)
      existingElement.insertAdjacentElement('beforeend', foreignObject)
    },
    getTextWidth (text, options = {}) {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = `${options.fontWeight || 'normal'} ${options.fontSize || '20px'} ${options.fontFamily || 'Nunito, sans-serif'}`
      const metrics = context.measureText(text)
      return metrics.width
    },
    splitTitle (title, maxWidth, options = {}) {
      const titleWidth = this.getTextWidth(title, options)

      if (titleWidth <= maxWidth) {
        return [title]
      }
      const words = title.split(' ')
      const result = []
      let currentLine = ''
      for (let i = 0; i < words.length; i++) {
        const word = words[i]
        const line = `${currentLine}${word} `
        const lineWidth = this.getTextWidth(line, options)
        if (lineWidth > maxWidth) {
          result.push(currentLine.trim())
          currentLine = `${word} `
        } else {
          currentLine = line
        }
      }
      result.push(currentLine.trim())
      return result
    },
    // split string to array with length l
    splitter (str, l) {
      if (l <= 0) {
        return [str]
      }
      var strs = []
      while (str.length > l) {
        var pos = str.substring(0, l).lastIndexOf(' ')
        pos = pos <= 0 ? l : pos
        strs.push(str.substring(0, pos))
        var i = str.indexOf(' ', pos) + 1
        if (i < pos || i > pos + l) {
          i = pos
        }
        str = str.substring(i)
      }
      strs.push(str)
      return strs
    },
    groupBy (array, key) {
      // Return the end result
      return array.reduce((result, currentValue) => {
        if (currentValue[key] === undefined) {
          return result
        }
        // If an array already present for key, push it to the array. Else create an array and push the object
        (result[currentValue[key]] = result[currentValue[key]] || []).push(
          currentValue
        )
        // Return the current iteration `result` value, this will be taken as next iteration `result` value and accumulate
        return result
      }, {}) // empty object is the initial value for result object
    },
    translate (value, key, lang) {
      var tmp = null
      if (lang === 'fr') {
        tmp = (value ? value[`${key}_fr`] : '')
      }
      if (lang === 'it') {
        tmp = (value ? value[`${key}_it`] : '')
      }
      if (key === 'title' || key === 'subtitle') {
        // this I need how values were implemented on backend and title and subtitle can be empty string. If I delete this if statements another translations will be broken
        return tmp || tmp === '' ? tmp : (value ? value[key] : '')
      }
      return tmp || (value ? value[key] : '')
    },
    alphabetByNumber (num) {
      const alphabet = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
      return alphabet[num]
    }
  }
}
