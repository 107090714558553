<template>
    <div class="flex flex-col gap-4" v-view.once="onceHandler">
        <chart-menu class='' v-if="!!chart" :chart="chart"></chart-menu>
        <div class="flex flex-col self-center">
            <h1 class="self-center">{{ getTranslatedTitle }}</h1>
            <h2 class="self-center">{{ getTranslatedSubtitle }}</h2>
        </div>
        <!-- display table -->
        <ve-table v-if="visible && !hideGraphic"
            :columns='getTableColumns'
            :table-data='getTableData'
            :sort-option='getSortOption'
        />
        <div v-if="hideGraphic" class="absolute top-1/2 left-1/2 -translate-x-1/2 text-lg font-bold">
      {{ $t('no-data') }}
        </div>
        <LimitModal :seriesLength="seriesLength" :currentCountOfCategories="currentCountOfCategories" :currentCountOfLabels="currentCountOfLabels" :maxCategories="MAX_CATEGORIES" :maxLabels="MAX_CATEGORIES_LABELS"/>
    </div>
</template>

<script>
import ChartMenu from '@/components/chartsFSP/ChartMenu.vue'
import LimitModal from '@/components/chartsFSP/LimitModal.vue'
import { mapGetters } from 'vuex'
import utilsMixin from '@/mixins/utils'

export default {
  mixins: [utilsMixin],
  components: {
    LimitModal,
    ChartMenu
  },
  props: {
    chart: {
      type: Object,
      default: null
    },
    language: {
      type: String,
      default: 'de'
    }
  },
  data () {
    return {
      MAX_CATEGORIES: 100,
      MAX_CATEGORIES_LABELS: 100,
      currentCountOfCategories: 0,
      currentCountOfLabels: 0,
      tableColumns: [],
      tableData: [],
      seriesLength: null,
      visible: false,
      hideGraphic: false,
      categoriesCount: [],
      countObservationsArr: [],
      sortOption: {
        sortChange: (params) => {
          this.sortChange(params)
        }

      }
    }
  },
  computed: {
    ...mapGetters({
      filteredDataForChartId: 'results/evaluationStore/filteredDataForChartId'
    }),
    computedData () {
      return this.filteredDataForChartId(this.chart.i, this.chart.filters)
    },
    getTableColumns () {
      return this.tableColumns
    },
    getTableData () {
      return this.tableData
    },
    getSortOption () {
      return this.sortOption
    },
    getTranslatedTitle () {
      return this.translate(this.chart, 'title', this.language)
    },
    getTranslatedSubtitle () {
      return this.translate(this.chart, 'subtitle', this.language)
    },
    getSelectedSeries () {
      return this.chart.data
    },
    getSplitSeries () {
      return this.chart.splits.length > 0 ? this.chart.splits[0].data : []
    },
    isMultiSplit () {
      return this.chart.splits?.length > 1
    },
    isSplit () {
      return this.chart.splits?.length > 0
    },
    isHorizontal () {
      return this.chart.viewSettings[1] === 'horizontal'
    },
    splitSeries () {
      return this.isSplit ? this.chart.splits[0].data : []
    }
  },
  methods: {
    checkLimitOfCategoriesInGraphic (currentCountOfCategories, currentCountOfLabels) {
      if (currentCountOfCategories > this.MAX_CATEGORIES ||
      currentCountOfLabels > this.MAX_CATEGORIES_LABELS) {
        this.hideGraphic = true
      }
    },
    setupData () {
      if (!this.visible || this.computedData.length <= 0) {
        this.tableColumns = []
        this.tableData = []
        return
      }

      this.getCategoriesCount = []
      // resultTableColumns and resultTableData - this is data for the table
      let resultTableColumns = []
      let resultTableData = []
      // run two methods to fullfill arrays with the data
      if (this.isMultiSplit) {
        // when there are 2 splits
        resultTableColumns = this.doubleSplitSeriesTableColumns(resultTableColumns, this.computedData)
        resultTableData = this.doubleSplitSeriesTableData(resultTableData, this.computedData)
      } else if (this.isSplit) {
        // when there is 1 split
        resultTableColumns = this.buildSplitTableColumns(resultTableColumns, this.computedData)
        resultTableData = this.buildSplitSeriesTableData(resultTableData, this.computedData)
      } else if (this.isHorizontal) {
        // run when it's horizontal and just one question is there
        resultTableColumns = this.buildSeriesTableColumnsHorizontal(resultTableColumns, this.computedData)
        resultTableData = this.buildSeriesTableDataHorizontal(resultTableData, this.computedData)
      } else {
        // when there is one question
        resultTableColumns = this.buildSeriesTableColumns(resultTableColumns, this.computedData)
        resultTableData = this.buildSeriesTableData(resultTableData, this.computedData)
      }

      // show pop-up window if results exceed threshold (keep fluent UX)
      this.hideGraphic = false
      this.checkLimitOfCategoriesInGraphic(resultTableColumns.length, resultTableData.length)
      this.currentCountOfCategories = resultTableColumns.length
      this.currentCountOfLabels = resultTableData.length
      this.tableColumns = resultTableColumns
      this.tableData = resultTableData
    },
    sortChange (params) {
      this.tableData.sort((a, b) => {
        if (params.percent) {
          if (params.percent === 'asc') {
            // delete last character from string that is: '%'
            const first = a.percent.substring(0, a.percent.length - 1)
            // delete last character from string that is: '%'
            const second = b.percent.substring(0, b.percent.length - 1)
            return first - second
          } else if (params.percent === 'desc') {
            // delete last character from string that is: '%'
            const first = a.percent.substring(0, a.percent.length - 1)
            // delete last character from string that is: '%'
            const second = b.percent.substring(0, b.percent.length - 1)
            return second - first
          } else {
            return 0
          }
        } else if (params.number) {
          if (params.number === 'asc') {
            return a.number - b.number
          } else if (params.number === 'desc') {
            return b.number - a.number
          } else {
            return 0
          }
        }
      })
    },
    buildSeriesTableColumns (resultTableColumns, computedData) {
      const arrTableColumns = []
      let indexCount = 0
      // push question-title to the first cell of the column and push empty stirng to the second cell of the column
      arrTableColumns.push({ field: 'row_name', key: this.alphabetByNumber(indexCount++), title: '', align: 'center', width: 140 })
      // push names to the cells in columns from getSelectedSeries
      this.getSelectedSeries[0].data.forEach((title, count) => {
        arrTableColumns.push({ field: count, key: this.alphabetByNumber(indexCount++), title: this.translate(title, 'label', this.language), align: 'center', width: 100 })
      })

      // push in the last cell in column string-total
      arrTableColumns.push({ field: 'totalAnswers', key: this.alphabetByNumber(indexCount++), title: 'Total', align: 'center', width: 100 })

      resultTableColumns = arrTableColumns
      // this is recurtion, when columns are fullfilled with data => return it back\
      return resultTableColumns
    },
    buildSeriesTableColumnsHorizontal (resultTableColumns, computedData) {
      const arrTableColumns = []
      let indexCount = 0

      arrTableColumns.push({ field: 'question', key: this.alphabetByNumber(indexCount++), title: this.translate(this.getSelectedSeries[0], 'label', this.language), align: 'left', width: 200 })
      arrTableColumns.push({ field: 'percent', key: this.alphabetByNumber(indexCount++), title: 'Prozent', align: 'center', sortBy: '', width: 140 })
      arrTableColumns.push({ field: 'number', key: this.alphabetByNumber(indexCount++), title: 'Anzahl', align: 'center', sortBy: '', width: 140 })

      resultTableColumns = arrTableColumns

      return resultTableColumns
    },
    buildSplitTableColumns (resultTableColumns, computedData) {
      const arrTableColumns = []
      let indexCount = 0

      arrTableColumns.push({ field: 'grouped_by', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.splits[0], 'label', this.language), align: 'center', width: 140 })
      arrTableColumns.push({ field: 'row_name', key: this.alphabetByNumber(indexCount++), title: '', align: 'center', width: 100 })

      // change it for computeddata insted using this.getSelectedSeries
      this.getSelectedSeries[0].data.forEach((title, count) => {
        arrTableColumns.push({ field: count, key: this.alphabetByNumber(indexCount++), title: this.translate(title, 'label', this.language), align: 'center', width: 100 })
      })

      arrTableColumns.push({ field: 'totalAnswers', key: this.alphabetByNumber(indexCount++), title: 'Total', align: 'center', width: 100 })

      resultTableColumns = arrTableColumns

      return resultTableColumns
    },
    doubleSplitSeriesTableColumns (resultTableColumns, computedData) {
      const arrTableColumns = []
      let indexCount = 0

      // push question-title to the first cell of the column, push to second cell of the column splitby-title
      // push to third cell of the column grouped_by-title and push empty string to the forth cell of the column
      arrTableColumns.push({ field: 'grouped_by', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.splits[1], 'label', this.language), align: 'center', width: 140 })
      arrTableColumns.push({ field: 'split_by', key: this.alphabetByNumber(indexCount++), title: this.translate(this.chart.splits[0], 'label', this.language), align: 'center', width: 140 })
      arrTableColumns.push({ field: 'row_name', key: this.alphabetByNumber(indexCount++), title: '', align: 'center', width: 140 })

      // push names to the cells in columns from getSelectedSeries
      this.getSelectedSeries[0].data.forEach((title, count) => {
        arrTableColumns.push({ field: count, key: this.alphabetByNumber(indexCount++), title: this.translate(title, 'label', this.language), align: 'center', width: 100 })
      })

      // push in the last cell in column string-total
      arrTableColumns.push({ field: 'totalAnswers', key: this.alphabetByNumber(indexCount++), title: 'Total', align: 'center', width: 100 })

      resultTableColumns = arrTableColumns

      // this is recurtion, when columns are fullfilled with data => return it back
      return resultTableColumns
    },
    buildSeriesTableData (resultTableData, computedData) {
      let calculatedResults = []

      // run loop from selectedSeries
      this.getSelectedSeries.forEach((serie, serieIx) => {
        if (serie.data) {
          // on each iteration run calculateResults function
          calculatedResults = this.calculateResults(calculatedResults, serie, computedData)
        }
      })

      resultTableData = calculatedResults
      // this is recurtion, when rows are fullfilled with data => return it back
      return resultTableData
    },
    buildSeriesTableDataHorizontal (resultTableData, computedData) {
      let calculatedResults = []

      this.getSelectedSeries.forEach((serie, serieIx) => {
        if (serie.data) {
          // on each iteration run calculateResults function
          calculatedResults = this.calculateResultsHorizontal(calculatedResults, serie, computedData)
        }
      })

      resultTableData = calculatedResults
      // this is recurtion, when rows are fullfilled with data => return it back
      return resultTableData
    },
    buildSplitSeriesTableData (resultTableData, computedData) {
      let calculatedFinalResults = []

      // loop throw groups for example when two qustions are groupedby-language
      this.chart.splits.forEach(group => {
        group.data.forEach((valueGroup, groupIx) => {
          var filtered = []

          // this function implemented Manuel Roth for filtering. It's filtered all data based on values from group. This function should be running every time when user choose groupby
          if (valueGroup.customFunc) {
            filtered = this.computedData.filter(d => valueGroup.customFunc(d.attributes[group.key]))
          } else {
            filtered = this.computedData.filter(d => valueGroup.value && d.attributes[group.key] && d.attributes[group.key].toString() === valueGroup.value.toString())
          }

          // run loop on selectedSeries for example when user choose any question
          this.getSelectedSeries.forEach((serie, serieIx) => {
            if (serie.data && filtered.length > 0) {
              // in function calculateSplitResults will sort all values and put it in right cells in table.
              // Also this function will run a lot times because there are few layout looping
              calculatedFinalResults = this.calculateSplitResults(calculatedFinalResults, serie, computedData, filtered, valueGroup)
            }
          })
        })
      })

      resultTableData = calculatedFinalResults

      // this is recurtion, when rows are fullfilled with data => return it back
      return resultTableData
    },
    doubleSplitSeriesTableData (resultTableData, computedData) {
      let calculatedFinalResults = []
      const outerSplit = this.chart.splits[1]

      // loop throw groups for example when two qustions are groupedby-language
      outerSplit.data.forEach((valueGroup, groupIx) => {
        var filtered = []

        // this function implemented Manuel Roth for filtering. It's filtered all data based on values from group. This function should be running every time when user choose groupby
        if (valueGroup.customFunc) {
          filtered = this.computedData.filter(d => valueGroup.customFunc(d.attributes[outerSplit.key]))
        } else {
          filtered = this.computedData.filter(d => valueGroup.value && d.attributes[outerSplit.key] && d.attributes[outerSplit.key].toString() === valueGroup.value.toString())
        }

        // run loop on splitSeries for example when it's splitby geschlect or age
        this.splitSeries.forEach((splitValue, splitCount) => {
          // run loop on selectedSeries for example when user choose any question
          this.getSelectedSeries.forEach((serie, serieIx) => {
            if (serie.data && filtered.length > 0) {
              // in function calculateSplitAndGroupedResults will sort all values and put it in right cells in table.
              // Also this function will run a lot times because there are few layout looping
              calculatedFinalResults = this.calculateDoubleSplitResults(calculatedFinalResults, serie, splitValue, filtered, valueGroup)
            }
          })
        })
      })

      resultTableData = calculatedFinalResults

      // this is recurtion, when rows are fullfilled with data => return it back
      return resultTableData
    },
    calculateResults (result, serie, data) {
      const resultObjCalulatedData = {}
      let calculatedResultsPercentages = {}

      // put question to cell to first row in Table
      calculatedResultsPercentages.question = this.translate(serie, 'label', this.language)
      // put string-Prozent to first row in Table
      calculatedResultsPercentages.row_name = 'Prozent'
      // put string-Anzahl to second row in Table
      resultObjCalulatedData.row_name = 'Anzahl'

      // put all calculated values in the second row in Table
      serie.data.forEach((v, ix) => {
        resultObjCalulatedData[ix] = resultObjCalulatedData[ix] || 0
        if (v.customFunc) {
          resultObjCalulatedData[ix] += data.filter(value => v.measure.id === value.measure && v.customFunc(value.attributes[v.key])).length
        } else {
          resultObjCalulatedData[ix] += data.filter(value => (value.attributes.record_id === 1 || serie.blow_in_graph !== true) && v.measure.id === value.measure && value.attributes[v.key] != null && String(value.attributes[v.key]) === String(v.value)).length
        }
      })

      // run calculateResultsPercentages function and put all calculated Percentages in first row in Table
      calculatedResultsPercentages = this.calculateResultsPercentages(calculatedResultsPercentages, resultObjCalulatedData)

      // fulfill result array with two new objects
      result.push(calculatedResultsPercentages)
      result.push(resultObjCalulatedData)

      return result
    },
    calculateResultsHorizontal (result, serie, data) {
      let calculatedResultsPercentages = {}

      const allCalculatedValues = {}

      // calculate percents and put them in one object
      serie.data.forEach((v, ix) => {
        allCalculatedValues[ix] = allCalculatedValues[ix] || 0

        if (v.customFunc) {
          allCalculatedValues[ix] += data.filter(value => v.measure.id === value.measure && v.customFunc(value.attributes[v.key])).length
        } else {
          allCalculatedValues[ix] += data.filter(value => (value.attributes.record_id === 1 || serie.blow_in_graph !== true) && v.measure.id === value.measure && value.attributes[v.key] != null && String(value.attributes[v.key]) === String(v.value)).length
        }
      })

      calculatedResultsPercentages = this.calculateResultsPercentagesHorizontal(calculatedResultsPercentages, allCalculatedValues)

      serie.data.forEach((v, ix) => {
        const resultObjCalulatedData = {}

        resultObjCalulatedData.number = resultObjCalulatedData[ix] || 0
        if (v.customFunc) {
          resultObjCalulatedData.number += data.filter(value => v.measure.id === value.measure && v.customFunc(value.attributes[v.key])).length
        } else {
          resultObjCalulatedData.number += data.filter(value => v.measure.id === value.measure && value.attributes[v.key] != null && String(value.attributes[v.key]) === String(v.value)).length
        }

        resultObjCalulatedData.question = v.value || v.label || 0
        // put percent in object that I'm looping now
        resultObjCalulatedData.percent = calculatedResultsPercentages[ix]

        result.push(resultObjCalulatedData)
      })

      return result
    },
    calculateDoubleSplitResults (result, serie, splitValue, filtered, valueGroup) {
      const resultObjCalulatedData = {}
      let calculatedResultsAll = {}

      // put question to cell to first row in Table
      calculatedResultsAll.question = this.translate(serie, 'label', this.language)
      // put splitby value to cell to first row in Table
      calculatedResultsAll.split_by = this.translate(splitValue, 'label', this.language)
      // put groupby value to cell to first row in Table
      calculatedResultsAll.grouped_by = this.translate(valueGroup, 'label', this.language)
      // put strings: Prozent and An to first row in Table
      // \n\n => I use it to put two words in one string but it will be in newline(line break)
      calculatedResultsAll.row_name = 'Prozent\n\nAnzahl'

      // put all calculated values in the FIRST row in Table
      serie.data.forEach((v, ix) => {
        resultObjCalulatedData[ix] = result[ix] || 0
        var value = 0
        value = filtered.filter(value => {
          var resultObjCalulatedData = false
          // function that Manuel implemented and it sorted correctly all splitby values and grupby values
          if (v.customFunc) {
            resultObjCalulatedData = v.customFunc(v.measure.id === value.measure && value.attributes[v.key])
          } else {
            if (value.attributes.record_id === 1 || serie.blow_in_graph !== true) {
              resultObjCalulatedData = String(v.measure.id === value.measure && value.attributes[v.key]) === String(v.value)
            }
          }

          if (splitValue.customFunc) {
            resultObjCalulatedData = splitValue.customFunc(value.attributes[splitValue.key]) && resultObjCalulatedData
          } else {
            resultObjCalulatedData = value.attributes[splitValue.key] != null && String(value.attributes[splitValue.key]) === String(splitValue.value) && resultObjCalulatedData
          }

          return resultObjCalulatedData
        }).length
        resultObjCalulatedData[ix] = value
      })

      // run calculateSplitAndGroupedResultsPercentages function and put all calculated Percentages and all values in one row in Table
      calculatedResultsAll = this.calculateSplitAndGroupedResultsPercentages(calculatedResultsAll, resultObjCalulatedData)

      // here is only onw raw per iteration
      result.push(calculatedResultsAll)

      return result
    },
    calculateSplitResults (result, serie, data, filtered, valueGroup) {
      const resultObjCalulatedData = {}
      // const calculatedResultsPercentages = {}
      let calculatedResultsAll = {} // new

      // put question to cell to first row in Table
      calculatedResultsAll.question = this.translate(serie, 'label', this.language)
      // put groupby value to cell to first row in Table
      calculatedResultsAll.grouped_by = this.translate(valueGroup, 'label', this.language)
      // put strings: Prozent and An to first row in Table
      // \n\n => I use it to put two words in one string but it will be in newline(line break)
      calculatedResultsAll.row_name = 'Prozent\n\nAnzahl'

      // put all calculated values in the FIRST row in Table
      serie.data.forEach((v, ix) => {
        resultObjCalulatedData[ix] = result[ix] || 0
        var value = 0

        value = filtered.filter(value => {
          // function that Manuel implemented and it sorted correctly all splitby values and groupby values
          var resultObjCalulatedData = false
          if (v.customFunc) {
            resultObjCalulatedData = v.customFunc(v.measure.id === value.measure && value.attributes[v.key])
          } else {
            if (value.attributes.record_id === 1 || serie.blow_in_graph !== true) {
              resultObjCalulatedData = String(v.measure.id === value.measure && value.attributes[v.key]) === String(v.value)
            }
          }

          return resultObjCalulatedData
        }).length
        resultObjCalulatedData[ix] = value
      })

      // run calculateSplitAndGroupedResultsPercentages function and put all calculated Percentages and all values in one row in Table
      calculatedResultsAll = this.calculateSplitAndGroupedResultsPercentages(calculatedResultsAll, resultObjCalulatedData) // new

      // here is only onw raw per iteration
      result.push(calculatedResultsAll)

      return result
    },
    calculateResultsPercentages (calculatedResultsPercentages, result) {
      let totalAnswers = 0

      // sum all answers together
      for (const key in result) {
        if (typeof result[key] === 'number') {
          totalAnswers += result[key]
        }
      }

      // put all calculated percentages
      for (const key in result) {
        if (typeof result[key] === 'number') {
          calculatedResultsPercentages[key] = totalAnswers > 0 ? ((result[key] / totalAnswers) * 100).toFixed(1) + '%' : 0
        }
      }

      // total prorcentages it's always 100% so can write it manualy
      calculatedResultsPercentages.totalAnswers = '100%'
      // total answers it's caluclated value - sum of all answers
      result.totalAnswers = totalAnswers

      // return it back
      return calculatedResultsPercentages
    },
    calculateResultsPercentagesHorizontal (calculatedResultsPercentages, result) {
      let totalAnswers = 0

      // sum all answers together
      for (const key in result) {
        if (typeof result[key] === 'number') {
          totalAnswers += result[key]
        }
      }

      // put all calculated percentages
      for (const key in result) {
        if (typeof result[key] === 'number') {
          calculatedResultsPercentages[key] = totalAnswers > 0 ? ((result[key] / totalAnswers) * 100).toFixed(1) + '%' : 0
        }
      }

      // return it back
      return calculatedResultsPercentages
    },
    calculateSplitAndGroupedResultsPercentages (calculatedResultsAll, result) {
      let totalAnswers = 0

      // sum all answers together
      for (const key in result) {
        if (typeof result[key] === 'number') {
          totalAnswers += result[key]
        }
      }

      // each iteration put calculated percentage and one calculated answer that are in one string and has newline separator(/n/n)
      for (const key in result) {
        if (typeof result[key] === 'number') {
          calculatedResultsAll[key] = totalAnswers > 0 ? ((result[key] / totalAnswers) * 100).toFixed(1) + '%' + `\n\n${result[key]}` : 0
        }
      }

      // total prorcentages it's always 100% so can write it manualy and add to this string total answers that are with newline separator(/n/n)
      calculatedResultsAll.totalAnswers = '100%' + `\n\n${totalAnswers}`

      return calculatedResultsAll
    },
    onceHandler (e) {
      this.visible = true
    }
  },
  watch: {
    getSelectedSeries: {
      handler (val) {
        this.setupData()
      }
    },
    getSplitSeries: {
      handler (val) {
        this.setupData()
      }
    },
    isHorizontal: {
      handler (val) {
        this.setupData()
      }
    },
    computedData: {
      handler (val) {
        this.setupData()
      }
    },
    visible: {
      handler (val) {
        this.setupData()
      }
    }
  },
  mounted () {
    this.setupData()
  }
}
</script>

<style>

</style>
